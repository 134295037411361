import "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";

import "bootstrap";
import "@fortawesome/fontawesome-free";

// scrollmenulist
let scrolling = false;
// @ts-ignore: Unreachable code error
const menu = [...document.querySelectorAll(".flyout a")].map(x => (x as any).hash);
const isInViewport = (elem: Element) => {
    const distance = elem.getBoundingClientRect();
    return (
        distance.top >= 0 &&
            distance.left >= 0 &&
            distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};
const scrollNavigationHightlight = () => {
    menu.map(item => {
            let elem = document.getElementsByName(decodeURI(item.substr(1, item.length)));
            if (isInViewport(elem[0])) {
                document.querySelectorAll(".flyout li").forEach(x => x.classList.remove("active"));
                (document.querySelectorAll(`.flyout a[href*="${decodeURI(item)}"]`)[0].parentNode as any).classList.add("active");
            }

        }
    );
}
setInterval(() => {
    if (scrolling) {
        scrolling = false;
        scrollNavigationHightlight();
    }
}, 50);
window.onscroll = () => scrolling = true;